import {UsageVariant} from '../app/models/usage_variants';

const protocol = 'https://';
const api_host = protocol + 'spn24.ru/';
// const api_host = '/';

export const environment = {
  test_version_raven: false,
  test_version: false,
  production: true,
  usage: UsageVariant.Frontend,
  envName: 'spn24',
  apiUrl: api_host + 'api/v1',
  apiServer: api_host,
  urlFileUpload: api_host + 'files/upload/',
  urlPriceHousing: api_host + 'restful/price/housing/',
  urlPresentationComplex: api_host + 'restful/pdf/complex/',
  urlPresentationHouse: api_host + 'restful/pdf/house/',
  urlPresentationFlat: api_host + 'restful/pdf/flat/',
  urlLead: api_host + 'restful/all_leads/',
  urlIntegration: api_host + 'restful/integration_leads/',
  urlKpi: api_host + 'restful/attraction_deal_kpi/',
  urlAttrKpi: api_host + 'restful/attraction_org_activation_kpi/',
  autocomplite: true,
  commonAppUrl: `${protocol}spn24.ru/`,
  tinyMcePluginName: 'tinyFiles24',
  yaMetrikaCounter: '50254164',
};
